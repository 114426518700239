<template>
  <div>
    <Header></Header>
    <div class="wrapper custom-opportunity-detail-page">
      <div v-if="isMobile === false" class="centerWidth">
        <div class="custom-opportunity-detail-box">
          <div class="content-box">
            <p class="detailT">{{ detail.title }}</p>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                line-height: 14px;
                margin-top: 16px;
                margin-bottom: 26px;
              "
            >
              <p style="margin-right: 20px">
                {{$t('opportunity.announceTime')}}：{{ detail.createTime }}
              </p>
              <p>{{$t('opportunity.aCompany')}}：{{ detail.enterprise_name }}</p>
            </div>
            <p class="detailT1">{{$t('opportunity.aDetail')}}</p>
            <!--      文章内容-->
            <div class="detailContent">
              <div
                v-html="detail.content"
                style="line-height: 2; text-align: justify"
              ></div>
            </div>
          </div>
          <Right :modules="rightModules"></Right>
        </div>

        <AiPop></AiPop>
      </div>
      <div v-if="isMobile === true" class="centerWidth m-opportunity-detail-page">
        <div class="CDHeader">
          <p>{{ detail.title }}</p>
        </div>
        <div class="CDBox">
          <span class="CDLine"></span>
          <span>{{$t('opportunity.brief')}}</span>
        </div>
        <div class="CDBox1">
          <p>{{$t('opportunity.startTime')}}：<span>{{ detail.start_time ? detail.start_time.split(' ')[0] : '' }}</span></p>
          <p>{{$t('opportunity.endTime')}}：<span>{{ detail.end_time ? detail.end_time.split(' ')[0] : '' }}</span></p>
          <p>{{$t('opportunity.enterpriseName')}}：<span>{{ detail.enterprise_name }}</span></p>
        </div>
        <div class="CDBox">
          <span class="CDLine"></span>
          <span>{{$t('opportunity.aDetail')}}</span>
        </div>
        
        <!-- <iframe :srcdoc="detail.content" frameborder="0"></iframe> -->
        <!-- <div></div> -->
        <div class="CDBox2" v-html="detail.content">
        </div>

        <MRight :modules="mRightModules"></MRight>

        <!-- <Right3></Right3> -->
        <ScrollTop></ScrollTop>
        <AiPop></AiPop>
      </div>
    </div>

    <Footer v-if="isMobile === false"></Footer>
   <Bottom v-if="isMobile === true">123</Bottom>
    <LoginTip
      :dialogVisible="dialogVisible"
      @hideDialog="hideDialog"
    ></LoginTip>
    <InfoTip
      :dialogVisibleInfo="dialogVisibleInfo"
      @hideDialog="hideInfoDialog"
    ></InfoTip>
  </div>
</template>

<script>
import AiPop from "@/components/AiPop.vue";
// import md5 from "js-md5";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
// import BottomBanner from "@/components/BottomBanner.vue";
import { shareUrl } from "@/utils/vxshare.js";
import LoginTip from "@/components/LoginTip.vue";
import InfoTip from "@/components/InfoTip.vue";
import Right from "@/components/Right.vue";
import ScrollTop from "@/components/mobile/ScrollTop.vue";
import Bottom from "@/components/mobile/Bottom.vue";
// import Right3 from "@/components/mobile/Right3.vue";
import MRight from "@/components/mobile/Right.vue";

import mixins from "@/utils/mixins.js";

// import { mapState } from 'vuex'

import { getNewsDetail } from "@/api/index";

export default {
  name: "articalDetail",
  mixins: [mixins],
  components: {
    ScrollTop,
    Bottom,
    InfoTip,
    LoginTip,
    // BottomBanner,
    Footer,
    Header,
    AiPop,
    Right,
    MRight,
    // Right3,
  },

  data() {
    return {
      HTMLtranslatedetailTitle: "",
      dialogVisibleInfo: false,
      dialogVisible: false,
      isMobile: true,
      languageSelect: "",
      translateThis: 1,
      maxTextLength: 100, // 设置最大字数
      searchKeywords: "",
      seconderyClassfy: [],
      secondaryTypeid: "",
      isLoading: "",
      articles: [],
      hovered: false,
      content: "",
      size: 8,
      page: 1,
      total: 1,
      change: 0,
      // trans: false,
      collect: 0,
      translatedetaiContent: "",
      detailTitle: "",
      detailTime: "",
      detailContent: "",
      translatedetailTitle: "",
      zhStr: "",
      enStr: "",
      salt: parseInt(Math.random() * 1000000000), //随机数
      appid: "20231016001849033",
      userkey: "ZgPv1FfEEWXPsNH0HzWl",
      accessToken: "",
      newsId: "",
      fanyi: "",
      q: "",
      token: localStorage.getItem("access_token"),
      userId: localStorage.getItem("userId"),
      buttonStyle: false, // 控制按钮样式的变量
      activeIndex: null, // 记录被点击的按钮索引
      AK: "P4qj3AkttTc6qdQGXOPorEMi",
      SK: "dBf1hilVcWZbqkooGzYvgVydCOf4LAGY",
      titleEn: "",
      contentEn: "",
      scanId: "",
      isView: "",
      isWhole: "",
      user: this.$store.state.user,

      detail: "",
      rightModules: [
        {
          type: "opportunity",
          path: 'opportunity',
          title: this.$t('right.latestBusiness'),
          hasMoreBtn: false,
          dataNum: 5,
          primaryTypeId: 3,
        },
      ],
      mRightModules: [
        {
          type: 'news',
          path: 'technology',
          title: this.$t('newsDetail.relateNews'),
          dataNum: 5,
          primaryTypeId: 1
        },
        {
          type: 'report',
          path: 'report',
          title: this.$t('right.latestRJ'),
          dataNum: 4,
          primaryTypeId: 2
        },
      ],
    };
  },
  async created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    window.scrollTo(0, 0);
    this.newsId = this.$route.query.newId;
    this.scanId = this.$route.query.Typeid;
    if (this.newId) {
      this.newsId = this.newId;
    }
    var page = {
      setPage: this.$route.query.currentPage,
      primaryTypeid: this.$route.query.Typeid,
      isFlag: this.$route.query.isFlag,
    };
    localStorage.setItem("setPage", JSON.stringify(page));

    // this.newsId = this.$route.params.id;
    await this.getNewsDetail(this.$route.params.id);
    this.setTKD(this.detail)

    // document.title = localStorage.getItem("Title");
    // console.log(this.$route.query.newId)
    let url = window.location.href;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: this.articles.title,
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 2000);

    // this.loginView();
  },
  mounted() {
    // this.baidutrans();
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
    // document.title = localStorage.getItem("Title");
  },
  methods: {
    async getNewsDetail(id) {
      let res = await getNewsDetail(id);

      this.detail = res.data;
    },
    setTKD(detail){
      let title = detail.title + '-' + this.$t(this.$route.meta.title);

      document.title = title;
      document.querySelector('meta[property="og:title"]').content = title;

      document.querySelector('meta[property="og:keywords"]').content = this.$t(this.$route.meta.keywords)
      document.querySelector('meta[property="og:description"]').content = detail.content ? this.setText(detail.content).substr(0, 100) : this.$t('common.defaultdDscription');
    },


    hideInfoDialog() {
      this.dialogVisibleInfo = false;
    },
    hideDialog() {
      this.dialogVisible = false;
    },
    loginView() {
      this.$axios
        .get("/api/category/optionSelect", {
          params: {
            level: 1,
            categoryId: this.scanId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.isView = res.data.data[0].isView;
          this.isWhole = res.data.data[0].isWhole;
          // 不需要登录
          if (this.isView === "1") {
            // if (this.languageSelect === "zh") {
            //   this.getartical();
            // } else {
            //   this.getArticalEn();
            // }
          }
          // 需要登录
          else {
            // 已经登
            console.log(this.user);
            console.log(this.userId);
            if (this.user) {
              // 无需完善
              if (this.isWhole === "1") {
                // if (this.languageSelect === "zh") {
                //   this.getartical();
                // } else {
                //   this.getArticalEn();
                // }
              }
              // 需要完善
              else {
                if (this.$store.state.typeInfo == 0) {
                  this.dialogVisibleInfo = true;
                } else {
                  // if (this.languageSelect === "zh") {
                  //   this.getartical();
                  // } else {
                  //   this.getArticalEn();
                  // }
                }
              }
            } else {
              this.dialogVisible = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getElementHeight(id) {
      const element = document.getElementById(id);
      if (element) {
        return element.offsetHeight;
      }
      return null; // or any other value to handle when element is not found
    },
    setElementHeight(id, height) {
      const element = document.getElementById(id);
      if (element) {
        element.style.height = `${height}px`;
      }
    },
    //收藏
    collectChange() {
      this.$axios
        .post("/api/collect/addOrDelete", {
          // params: {
          userId: this.user.userId,
          newsId: this.newsId,
          // },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data.isCollect);
          this.collect = res.data.data.isCollect;
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 翻译
    async translate() {
      if (this.translateThis === 1) {
        this.translateThis = 0;
        this.trans = true;
      } else {
        this.translateThis = 1;
        this.trans = false;
      }
      /* 从页面获取选择的目标语言 传入url */
      this.to = "en";
      /* 对待翻译字符做url编码 */
      this.from = "zh";

      this.$axios
        .post(
          "/api/enes/translateByTitle",
          {
            newsId: this.newsId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.HTMLtranslatedetailTitle = res.data.data;
          this.translatedetailTitle = this.removeHtmlTags(
            this.HTMLtranslatedetailTitle
          );
        })
        .catch((error) => {
          console.log(error);
        });

      this.$axios
        .post(
          "/api/enes/translateByContent",
          {
            newsId: this.newsId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: this.token,
            },
          }
        )
        .then((res) => {
          this.translatedetaiContent = res.data.data;

          // 假设你的IP地址是 ipAddress，替换成你的IP地址
          const ipAddress = "https://www.oilgasinfoai.com";

          // 用正则表达式来匹配图像标签的src属性
          const imgRegex = /<img\s[^>]*src="([^"]*)"[^>]*>/gi;

          // 将IP地址添加到图像标签的src属性前，并添加样式
          this.translatedetaiContent = this.translatedetaiContent.replace(
            imgRegex,
            (match, src) => {
              let imgSrc = src.indexOf("http") > -1 ? src : ipAddress + src;
              return `<img style="display: block; width: 40%; height: auto; object-fit: contain; background-size: contain; background-position: center; background-repeat: no-repeat; margin: 0.1% 30% 1% 30%; box-shadow: 5px 5px 5px #888888;" src="${imgSrc}" />`;
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });

      // 延迟执行一个函数，延迟时间为5000毫秒（5秒）
      setTimeout(() => {
        // 在这里放置希望延迟执行的代码
      }, 5000);
    },

    removeHtmlTags(input) {
      return input.replace(/<[^>]*>/g, "");
    },

    baidutrans() {
      this.$nextTick(() => {
        const container1Height = this.getElementHeight("container1");
        const container2Height = this.getElementHeight("container2");
        if (container1Height && container2Height) {
          const maxHeight = Math.max(container1Height, container2Height);
          this.setElementHeight("container1", maxHeight);
          this.setElementHeight("container2", maxHeight);
        }
      });
    },

    getartical() {
      // console.log("新闻"+this.newsId)
      this.$axios
        .get("/api/news/" + this.newsId, {
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          console.log(res.data.data);
          this.articles = res.data.data; // 将响应中的数据直接赋值给 articles 对象数组
          // 重新生成列表
          this.list();
        })
        .catch((error) => {
          console.log(error);
        });
      this.$axios
        .get("/api/news/getNews/list", {
          params: {
            primaryTypeid: this.primaryTypeid,
            secondaryTypeid: this.secondaryTypeid,
            title: this.searchKeywords,
            pageNum: this.page,
            pageSize: this.size,
          },
          headers: {
            Authorization: this.token,
          },
        })
        .then((res) => {
          this.total = res.data.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
      // // 动态调整页面高度
      // this.adjustPageHeight();
    },
    getArticalEn() {
      var vm = this;
      vm.$axios
        .post("/api/enes/trans", {
          // params: {
          newsId: this.newsId,
          // },
          headers: {
            Authorization: vm.token,
          },
        })
        .then((res) => {
          vm.titleEn = res.data.data.enTitle;
          vm.contentEn = res.data.data.enContent;
          localStorage.setItem("Title", vm.titleEn);
          document.title = vm.titleEn; // 替换为实际文章标题
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    list() {
      this.content = this.articles.content;
      const currentDate = new Date(this.articles.createTime);
      const year = currentDate.getFullYear();
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      const day = ("0" + currentDate.getDate()).slice(-2);
      const formattedDate = `${year}-${month}-${day}`;
      // this.primaryTypeid[i] = this.articles[i].primaryTypeid
      // this.secondaryTypeid[i] = this.articles[i].secondaryTypeid
      this.articles.fanyi = this.stripHtmlTags(this.articles.content);
      if (this.articles.content.length > this.maxTextLength) {
        const truncatedText =
          this.articles.fanyi.slice(0, this.maxTextLength) + "…";
        this.articles.truncatedContent = truncatedText; // 保存截断后的内容到新字段
      } else {
        this.articles.truncatedContent = this.articles.fanyi; // 如果没有截断，保存原始内容
      }
      this.articles.leighttitle = this.articles.title.replace(
        new RegExp(this.searchKeywords, "gi"),
        (match) => `<span style="color: var(--main-color)">${match}</span>`
      );
      this.articles.createTime = formattedDate;

      // 使用简单的字符串替换来处理src属性
      let newContent = this.content.replace(
        /<img [^>]*src=['"]([^'"]+)[^>]*>/gi,
        (match, p1) => {
          let imgSrc = p1.indexOf("http") > -1 ? p1 : this.$utils.baseURL + p1;
          return `<img style="display: block; width: 40%; height: auto; object-fit: contain; background-size: contain; background-position: center; background-repeat: no-repeat; margin: 0.1% 30% 1% 30%; box-shadow: 5px 5px 5px #888888;" src="${imgSrc}" />`;
        }
      );
      this.articles.content = newContent;
      this.content = newContent;
      this.detailTitle = this.articles.title;
      localStorage.setItem("Title", this.detailTitle);
      document.title = this.detailTitle; // 替换为实际文章标题
      this.detailContent = this.articles.content;
      this.q = this.articles.fanyi;
      this.detailTime = this.articles.createTime;
      this.newsId = this.articles.id;
      this.collect = this.articles.isCollect;
    },
    stripHtmlTags(html) {
      return html.replace(/<[^>]*>/g, ""); // 使用正则表达式去除HTML标记
    },
  },
};
</script>


<style scoped lang="scss">
// .wrapper {
//   background: #f2f3f5;
// }

.fload {
  width: 15px;
  min-height: 75vh;
  margin-top: 5vh;
  border-radius: 15px;
  margin-bottom: 5vh;
  background-image: url("../../assets/fload.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: var(--main-color);
}

.limitTitLe {
  width: 50%;
  font-size: 22px;
  font-weight: bold;
  margin-top: 1%;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.pagination-container {
  position: sticky;
  margin-top: auto;
  bottom: 0;
  width: 99%;
  text-align: center;
  background-color: #fff;
}

.buttonStyle1 {
  font-weight: bold;
  font-size: 20px;
  color: black;
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.buttonStyle2 {
  font-weight: bold;
  font-size: 20px;
  color: var(--main-color);
  width: 250px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.subscribe {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribe.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

.subscribeDeny {
  position: absolute;
  border: none;
  margin: 5px 0 0 125px;
  width: 10px;
  height: 20px;
  background-image: url("../../assets/subscribeDeny.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent; /* 这里设置背景颜色为透明 */
}

li {
  list-style-type: none;
}

.news {
  margin-top: 1%;
  display: flex;
  margin-left: 7%;
  width: 85%;
}

.secondery {
  width: 20%;
  height: 100%;
  //border: black 1px solid;
}

.list {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin-left: 2%;
  width: 100%;
  //border: black 1px solid;
}

.list1 {
  min-height: 75vh;
  height: auto; /* 高度自动增加以适应内容 */
  padding-top: 5px;
  background-color: white;
  margin: 1% 1% 60px 1%;
  width: 98%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.talk_sub {
  font-size: 5px;
  width: 50%;
  margin-top: 15vh;
  height: 30vh;
  border-radius: 10px;
  background-image: url("../../assets/none.png");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.talk_word {
  display: flex;
  min-height: 40px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 60%;
  margin-left: 17.5%;
  max-height: 40px;
  padding: 0px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  border: rgb(84, 82, 82) 1px solid;
  background-color: white; /* 半透明白色，alpha值为0.5 */
}

.cascader {
  width: 20%;
  border-color: white !important;
}

.secondery {
  font-weight: bold;
  font-size: 20px;

  color: black;
  width: 160px;
  height: 55px;
  border-right: #2c3e50;
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 4px 2px var(--main-color);
}

.secondery :active {
  color: var(--main-color);
}

.talkcontent {
  min-height: 22px;
  white-space: pre-wrap; /* 使文本根据内容自动换行 */
  outline: none;
  width: 40%;
  max-height: 22px;
  padding: 3px;
  float: left;
  text-indent: 10px;
  border-radius: 8px;
  overflow: auto;
  margin-left: 2%;
  margin-top: 0.5%;
  //border: black solid 1px; background-color: white; /* 半透明白色，alpha值为0.5 */
}

::v-deep .el-cascader .el-input__inner:hover {
  border: none;
}

.logo {
  background-image: url("../../assets/artical.png");
  width: 30px;
  height: 30px;
  margin: 1.5% 2% 0 1.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.backlogo {
  background-image: url("../../assets/back.png");
  width: 50px;
  height: 50px;
  margin: 1.5% 2% 0 3%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.collect {
  border: none;
  background-image: url("../../assets/collect.png");
  width: 10px;
  height: 10px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.collect:hover {
  transform: scale(1.1); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.collectReally {
  background-image: url("../../assets/collectReally.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.translateThis {
  border: none;
  background-image: url("../../assets/trans.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.translateThis:hover {
  transform: scale(1.2); /* 设置悬停时的放大比例，可以根据需要调整 */
  transition: transform 0.3s ease-in-out; /* 添加过渡效果 */
}

.translateThisReally {
  background-image: url("../../assets/trans.png");
  width: 14px;
  height: 14px;
  text-align: right;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
}

.artical_height {
  height: 90vh;
}

.artical {
  width: 90%;
  border: none;
  border-radius: 0px;
  border-bottom: rgba(44, 62, 80, 0.66) 1px dashed;
  height: 150px;
  margin-left: 5%;

  //box-shadow:  0 0 0.1em 0 rgba(240, 76, 76, 0.5);
}

.artical:hover {
  background-color: rgba(240, 76, 76, 0.05);
  //transform: translateY(-5px);
}

.detailContent {
  // margin-left: 7.5%;
  // width: 85%;
  margin-bottom: 18px;
  font-size: 14px;
  color: #333333;
  min-height: 75vh;
  height: auto;
}

.custom-cascader .el-cascader-input input {
  border: none !important;
}

.custom-cascader .el-cascader-menu {
  border: 1px solid #fff !important; /* 设置下拉菜单边框为白色 */
}

.limitContent {
  margin: 1.5% 3% 0 0;
  width: 80%; /* 调整为你需要的宽度 */
  height: 80px;
  color: gray;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  p {
    text-align: left;
  }
}

.limit {
  text-align: left;
  color: gray;
  margin: 15px 5% 0 3%;
  width: 58vw; /* 调整为你需要的宽度 */
  height: 55px;
  overflow: hidden;

  p {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}

.limitImg {
  margin: 0.5% 3% 0 3%;
  width: 10%; /* 调整为你需要的宽度 */
  height: 65px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.detail1 {
  margin: 0.5% 5% 0% 3%;
  width: 95%; /* 调整为你需要的宽度 */
  height: 90%;
  word-wrap: break-word;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.transborder {
  flex: 1;
  border-radius: 5px;
  width: 100%;
  margin-right: 1%;
  min-height: 75vh;
  height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.detailBg {
  display: block;
  width: 1160px !important;
  height: 90px !important;
}

//me
.content-box {
  // width: 830px;
  padding: 0 30px;
}
.detailT {
  text-align: center;
  /* height: 24px; */
  font-size: 24px;
  font-weight: 500;
  // padding: 20px 20px 0;
  color: #333333;
  line-height: 40px;
}

.detailT1 {
  height: 16px;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 16px;
  padding-left: 4px;
  border-left: 2px #ff8803 solid;
  // margin-left: 50px;
}

.detailT2 {
  margin-top: 20px;
  font-size: 14px;
  color: #262626;
  line-height: 22p;
}

.detailT3 {
  display: block;
  width: 180px !important;
  height: 40px;
  background: #1a79ff;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  width: 100%;
}

.detailT4 {
  font-weight: 700;
  color: #1a79ff;
  padding-bottom: 8px;
}

.detailG {
  background-color: #1a79ff;
  transition: all 0.2s cubic-bezier(0.18, 0.89, 0.17, 0.88) 0s,
    opacity 0.15s ease 0s;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  border-radius: 1.5px;
  margin-top: 2px;
}

.el-button--mini {
  height: 20px;
  color: #a1a8b3;
  background-color: #f2f7ff;
}

.el-button--mini:hover {
  color: #fff;
  background: #1a79ff;
}

::v-deep .el-tabs__item {
  font-weight: 700;
  font-size: 22px;
  min-height: 30rem;
  height: 30rem;
}

.CDHeader {
  width: 96%;
  // height: 40px;
  // line-height: 40px;
  line-height: 1.5;
  padding: 10px;
  margin: 10px auto;
  // font-weight: bold;
  font-size: 16px;
  background: #fff;
}

.CDBox {
  width: 96%;
  margin: 10px;
  // padding-left: 10px;
  font-size: 14px;
}

.CDLine {
  width: 2px;
  height: 15px;
  border-radius: 0.03rem;
  background: red;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.CDBox1 {
  width: 96%;
  height: 100px;
  line-height: 30px;
  margin: 10px auto;
  padding: 8px 10px;
  background: #fff;
}

.CDBox2 {
  width: 96%;
  // height: 100px;
  min-height: 260px;
  // max-height: 800px;
  // line-height: 30px;
  line-height: 2;
  margin: 10px auto;
  padding: 8px 10px;
  background: #fff;
  overflow: auto;
}

.CDBox2T1 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bolder;
}

.custom-opportunity-detail-page{
  background-color: #f2f3f5;
  .custom-opportunity-detail-box{
    display: flex;
    margin: 20px 0;
    justify-content: space-between;
    .content-box{
      background-color: #fff;
      padding: 20px 30px;
    }
    .rightBox{
      height: fit-content;
      padding: 20px;
      background-color: #fff;
    }
  }
}
.m-opportunity-detail-page{
  font-size: 14px;
  margin: 10px auto 20px;
  .rightBox{
    width: 96%;
    margin: 0 auto;
    padding: 14px;
    background-color: #fff;
  }
}

</style>


